import React, { useEffect } from "react";

import { useHistory } from "react-router-dom";

const LogoutRedirect = () => {
  const history = useHistory();
  useEffect(() => {
    history.push("/accounts/logout");
  }, [history]);
  return <></>;
};

export default LogoutRedirect;
