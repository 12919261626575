import axios, { AxiosResponse } from "axios";

import environment from "../environment";

import { oktaAuth } from "./oktaAuth";

interface RegProgram {
  id?: number;
  reg_id_request?: boolean;
  reg_num?: string;
  reg_num_create_date?: string;
  customer_contact_name?: string;
  customer_contact_email?: string;
  salesforce_account_id?: string;
  is_in_salesforce?: boolean;
}

interface RegProgramPlusDw {
  email?: string;
  accouunt_id?: string;
  company_code?: string | null;
  reg_num?: string | null;
}

const edbApiInstance = axios.create({
  baseURL: environment.accountsApi.url,
  timeout: 50000,
});

const responseBody = (response: AxiosResponse) => response.data;

const datawarehouseRequest = {
  get: (url: string) =>
    edbApiInstance
      .get<RegProgram>(url, {
        headers: {
          Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
        },
      })
      .then(responseBody),
};

const RegistrationProgramApi = {
  getRegProgramData: (): Promise<RegProgram | undefined> =>
    datawarehouseRequest.get("/customer-reg-program"),
  getRegAndSalesforceData: (): Promise<RegProgramPlusDw | undefined> =>
    datawarehouseRequest.get("/customer-reg-program/plus"),
};

export const registrationProgramApi = () => {
  const regProgram = RegistrationProgramApi.getRegProgramData()
    .then((data) => {
      return data;
    })
    .catch((e) => {
      console.log(e);
    });

  return regProgram;
};

export const regProgramAndSalesforceDwApi = () => {
  const data = RegistrationProgramApi.getRegAndSalesforceData()
    .then((data) => {
      return data;
    })
    .catch((e) => {
      console.log(e);
    });

  return data;
};
